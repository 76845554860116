import { sortByValue } from "../../utils";
import { leadStatusChoices } from "../../constants";

export const PARAMETER_CATEGORY_CHOICES = [
  { value: "CONDITION", label: "Condition" },
  { value: "PROFILE", label: "Profile" },
  { value: "TREATMENT", label: "Treatment" },
  { value: "TEST", label: "Test" },
];

export const PARAMETER_STATUS_CHOICES = [
  { value: "ACTIVE", label: "Active" },
  { value: "PAST", label: "Past" },
  { value: "FAMILY HISTORY", label: "Family History" },
  { value: "RULED OUT", label: "Ruled Out" },
  { value: "TBD", label: "TBD" },
  { value: "FALSE POSITIVE", label: "False Positive" },
  { value: "NA", label: "N/A" },
];

export let PARAMETER_STATUS_CHOICES_MAP = {};
for (const p of PARAMETER_STATUS_CHOICES) {
  PARAMETER_STATUS_CHOICES_MAP[p.value] = p.label;
}

export const PARAMETER_VERIFICATION_STATUS_CHOICES = [
  { value: "VALIDATED BY HUMAN", label: "Validated -  By Human" },
  { value: "VALIDATED BY MACHINE", label: "Validated - By Machine" },
  { value: "TBD", label: "TBD" },
  { value: "FALSE POSITIVE EDUCATIONAL NOTATION", label: "False Positive - Educational Notation" },
  { value: "FALSE POSITIVE RULED OUT", label: "False Positive - Ruled Out" },
  { value: "FALSE POSITIVE FAMILY HISTORY", label: "False Positive - Family History" },
  { value: "NA", label: "N/A" },
];

export const PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS = [
  { value: "Autoimmune", label: "Autoimmune" },
  { value: "Blood Disorder", label: "Blood Disorder" },
  { value: "Cancer", label: "Cancer" },
  { value: "Cardiac", label: "Cardiac" },
  { value: "Cardiovascular", label: "Cardiovascular" },
  { value: "Gastrointestinal", label: "Gastrointestinal" },
  { value: "Genetic Disorders", label: "Genetic Disorders" },
  { value: "Infectious Disease", label: "Infectious Disease" },
  { value: "Liver", label: "Liver" },
  { value: "Muscular Skeletal", label: "Muscular Skeletal" },
  { value: "Neurological", label: "Neurological" },
  { value: "Psychological", label: "Psychological" },
  { value: "Renal", label: "Renal" },
  { value: "Respiratory", label: "Respiratory" },
];

export const PARAMETER_SUB_CATEGORY_CHOICES__PROFILES = [
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Demographic", label: "Demographic" },
  { value: "Family History", label: "Family History" },
  { value: "Functional", label: "Functional" },
  { value: "Substance Use", label: "Substance Use" },
];

export const PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS = [
  { value: "Prescription", label: "Prescription" },
  { value: "OTC", label: "OTC" },
  { value: "Procedure", label: "Procedure" },
  { value: "Device", label: "Device" },
];

export const PARAMETER_SUB_CATEGORY_CHOICES = sortByValue([
  ...PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS,
  ...PARAMETER_SUB_CATEGORY_CHOICES__PROFILES,
  ...PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS,
]);

export const PUBLICATION_CHOICES = [
  { value: "Allergy", label: "Allergy" },
  { value: "Annals of Internal Medicine", label: "Annals of Internal Medicine" },
  { value: "Annals of Oncology", label: "Annals of Oncology" },
  { value: "Annual Review of Immunology", label: "Annual Review of Immunology" },
  { value: "Archives of Neurology & Psychiatry (1919-1959)", label: "Archives of Neurology & Psychiatry (1919-1959)" },
  { value: "Autoimmunity Reviews", label: "Autoimmunity Reviews" },
  { value: "Biomedicine & Pharmacotherapy", label: "Biomedicine & Pharmacotherapy" },
  { value: "BMJ", label: "BMJ" },
  { value: "Cancer Cell", label: "Cancer Cell" },
  { value: "Cancer Discovery", label: "Cancer Discovery" },
  { value: "Cancer Letters", label: "Cancer Letters" },
  { value: "Cancer Research", label: "Cancer Research" },
  { value: "Cancers", label: "Cancers" },
  { value: "Cell Host & Microbe", label: "Cell Host & Microbe" },
  { value: "Cell Metabolism", label: "Cell Metabolism" },
  { value: "Cellular & Molecular Immunology", label: "Cellular & Molecular Immunology" },
  { value: "Clinical Cancer Research", label: "Clinical Cancer Research" },
  { value: "Cochrane Database of Systematic Reviews", label: "Cochrane Database of Systematic Reviews" },
  { value: "European Journal of Immunology", label: "European Journal of Immunology" },
  { value: "Fish & Shellfish Immunology", label: "Fish & Shellfish Immunology" },
  { value: "Frontiers in Immunology", label: "Frontiers in Immunology" },
  { value: "Frontiers in Oncology", label: "Frontiers in Oncology" },
  { value: "Immunity", label: "Immunity" },
  { value: "Immunological Reviews", label: "Immunological Reviews" },
  { value: "International Immunopharmacology", label: "International Immunopharmacology" },
  { value: "International Journal of Molecular Sciences", label: "International Journal of Molecular Sciences" },
  { value: "JAMA", label: "JAMA" },
  { value: "JAMA Cardiology", label: "JAMA Cardiology" },
  { value: "JAMA Dermatology", label: "JAMA Dermatology" },
  { value: "JAMA Health Forum", label: "JAMA Health Forum" },
  { value: "JAMA Internal Medicine", label: "JAMA Internal Medicine" },
  { value: "JAMA Network Open", label: "JAMA Network Open" },
  { value: "JAMA Neurology", label: "JAMA Neurology" },
  { value: "JAMA Oncology", label: "JAMA Oncology" },
  { value: "JAMA Ophthalmology", label: "JAMA Ophthalmology" },
  { value: "JAMA Otolaryngology–Head & Neck Surgery", label: "JAMA Otolaryngology–Head & Neck Surgery" },
  { value: "JAMA Pediatrics", label: "JAMA Pediatrics" },
  { value: "JAMA Psychiatry", label: "JAMA Psychiatry" },
  { value: "JAMA Surgery", label: "JAMA Surgery" },
  { value: "Journal for ImmunoTherapy of Cancer", label: "Journal for ImmunoTherapy of Cancer" },
  { value: "Journal of Allergy and Clinical Immunology", label: "Journal of Allergy and Clinical Immunology" },
  { value: "Journal of Autoimmunity", label: "Journal of Autoimmunity" },
  { value: "Journal of Clinical Medicine", label: "Journal of Clinical Medicine" },
  { value: "Journal of Clinical Oncology", label: "Journal of Clinical Oncology" },
  {
    value: "Journal of Experimental & Clinical Cancer Research",
    label: "Journal of Experimental & Clinical Cancer Research",
  },
  { value: "Journal of Experimental Medicine", label: "Journal of Experimental Medicine" },
  { value: "Journal of Hematology & Oncology", label: "Journal of Hematology & Oncology" },
  { value: "Journal of Immunology Research", label: "Journal of Immunology Research" },
  { value: "Journal of Thoracic Oncology", label: "Journal of Thoracic Oncology" },
  { value: "Leukemia", label: "Leukemia" },
  { value: "Molecular Cancer", label: "Molecular Cancer" },
  { value: "Nature Immunology", label: "Nature Immunology" },
  { value: "Nature Medicine", label: "Nature Medicine" },
  { value: "Nature Reviews Cancer", label: "Nature Reviews Cancer" },
  { value: "Nature Reviews Clinical Oncology", label: "Nature Reviews Clinical Oncology" },
  { value: "Nature Reviews Immunology", label: "Nature Reviews Immunology" },
  { value: "Oncogene", label: "Oncogene" },
  { value: "PLoS ONE", label: "PLoS ONE" },
  {
    value: "Proceedings of the National Academy of Sciences",
    label: "Proceedings of the National Academy of Sciences",
  },
  { value: "Science Immunology", label: "Science Immunology" },
  { value: "Science Translational Medicine", label: "Science Translational Medicine" },
  {
    value: "The Journal of Allergy and Clinical Immunology: In Practice",
    label: "The Journal of Allergy and Clinical Immunology: In Practice",
  },
  { value: "The Journal of Clinical Investigation", label: "The Journal of Clinical Investigation" },
  { value: "The Journal of Immunology", label: "The Journal of Immunology" },
  { value: "The Lancet", label: "The Lancet" },
  { value: "The Lancet Global Health", label: "The Lancet Global Health" },
  { value: "The Lancet Oncology", label: "The Lancet Oncology" },
  { value: "The New England Journal of Medicine", label: "The New England Journal of Medicine" },
  { value: "Theranostics", label: "Theranostics" },
  { value: "Trends in Immunology", label: "Trends in Immunology" },
];
