export const sideButtonStyle = {
  height: "26px",
  position: "absolute",
  right: "30px",
  top: "-52px",
  padding: "0 5px",
  marginTop: 1,
  fontSize: 14,
  zIndex: 1,
};
