import React from "react";
import { connect } from "react-redux";

import {
  editCaseWithoutDispatch,
  fetchCaseFilesByCaseDocumentId,
  selectAccount,
  selectAccountTask,
  selectActiveRecordId,
  selectCaseFile,
  showModal,
} from "actions";
import { accountCasesSelector, loadingCasesSelector, managementChoicesSelector } from "reducers";
import Table, { choiceCell, currencyCell, dateCell } from "components/table";
import IconLink from "../../../components/IconLink";
import { FaEdit } from "react-icons/fa";

const RecordsTable = ({ accountCases, showModal, casesLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <IconLink
            to={`/cases/${caseId}/policy/`}
            Icon={FaEdit}
            iconConfig={{ className: "table--action-icon" }}
            tooltip="Manage Life Settlement Case"
          />
        );
      },
    },
    {
      Header: "Application ID",
      accessor: "submission_id",
      className: "wrap-text",
    },
    {
      Header: "Stage",
      accessor: "stage",
      className: "wrap-text",
    },
    {
      Header: "Case Status",
      accessor: "status_name",
      className: "wrap-text",
    },
    {
      Header: "Insured Names",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "carrier",
      className: "wrap-text",
    },
    {
      Header: "Policy Number",
      accessor: "policy_number",
      className: "wrap-text",
    },
    {
      Header: "Face Amount",
      accessor: "face_amount",
      className: "wrap-text",
      Cell: currencyCell,
    },
    {
      Header: "Account Role",
      accessor: "date_created",
      className: "wrap-text",
    },
    {
      Header: "Source",
      Cell: choiceCell(props.accountChoices),
      accessor: "source",
      className: "wrap-text",
    },
    {
      Header: "Broker",
      Cell: choiceCell(props.accountChoices),
      accessor: "broker",
      className: "wrap-text",
    },
    {
      Header: "Sales Rep",
      accessor: "sales_representative_name",
      className: "wrap-text",
    },
    {
      Header: "Sales Manager",
      accessor: "sales_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account Manager",
      accessor: "national_account_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account",
      accessor: "national_account_name",
      className: "wrap-text",
    },
    {
      Header: "Create Date",
      accessor: "submission_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={accountCases}
      emptyMessage={casesLoading ? "Loading data..." : "No data"}
      initialPageSize={100}
      defaultSort={true}
      defaultPagination={true}
      sortBy={[{ id: "record_id", desc: false }]}
    />
  );
};

const mapStateToProps = state => {
  const accountCases = accountCasesSelector(state);
  const choices = managementChoicesSelector(state);

  return {
    accountCases,
    carrierChoices: choices.carriers,
    accountChoices: choices.accounts,
    casesLoading: loadingCasesSelector(state),
    salesRepChoices: choices.case_sales_representatives,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectCaseFile,
  fetchCaseFilesByCaseDocumentId,
  editCaseWithoutDispatch,
  selectAccount,
  selectAccountTask,
  selectActiveRecord: selectActiveRecordId,
})(RecordsTable);
