import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import { hideModal } from "../modals";
import { ADD_CONDITION_MODAL, MORTALITY_ASSESSMENT_MODAL } from "../../components/modals";
import * as notifications from "../../notifications";
import { URLS } from "constants.js";
import { redirectTo } from "../../utils";

export const selectMortalityAssessment = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_MORTALITY_ASSESSMENT, payload: id });
  const response = await advisorhq.get("/api/mortality_assessment/" + id + "/");
  dispatch({ type: types.FETCH_MORTALITY_ASSESSMENT, payload: response.data });
};

export const clearSelectedMortalityAssessment = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_MORTALITY_ASSESSMENT, payload: null });
};

export const fetchMortalityAssessments = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { parameters: true } });
  const response = await advisorhq.get("/api/mortality_assessment/", {
    params: {
      ...filters,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_MORTALITY_ASSESSMENTS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_MORTALITY_ASSESSMENTS, payload: results });
  dispatch({ type: types.LOADING, payload: { parameters: false } });
};

export const createMortalityAssessmentSummary = id => async (dispatch, getState) => {
  notifications.success("Generating Summary...");
  const endpoint = `/api/mortality_assessment/${id}/summary/`;
  await advisorhq.post(endpoint);
};

export const createMortalityAssessment = data => async (dispatch, getState) => {
  const endpoint = `/api/mortality_assessment/`;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_MORTALITY_ASSESSMENT, payload: response.data });
  dispatch(hideModal(MORTALITY_ASSESSMENT_MODAL));
  window.scrollTo(0, 0);
  redirectTo(`${URLS.MORTALITY_ASSESSMENTS}${response.data.id}/summary/`);
  notifications.success("A new Mortality Assessment was created");
};

export const editMortalityAssessment = (parameterId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/mortality_assessment/${parameterId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
  dispatch({ type: types.EDIT_MORTALITY_ASSESSMENT, payload: response.data });
};

export const deleteMortalityAssessment = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/mortality_assessment/${id}/`);
  dispatch({ type: types.DELETE_MORTALITY_ASSESSMENT, payload: id });
};

export const storeMortalityAssessmentsFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: types.STORE_MORTALITY_ASSESSMENTS_FILTERS_DATA, payload: filters });
};

export const findCasesForMortalityAssessment = filters => async (dispatch, getState) => {
  const response = await advisorhq.get("/api/find_cases/", {
    params: {
      ...filters,
    },
  });
  console.log(response.data);
  return response.data;
};

export const selectMortalityAssessmentCondition = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_MORTALITY_ASSESSMENT_CONDITION, payload: id });
};

export const createMortalityAssessmentCondition = data => async (dispatch, getState) => {
  const endpoint = `/api/mortality_assessment_condition/`;
  await advisorhq.post(endpoint, data);
  dispatch(hideModal(ADD_CONDITION_MODAL));
  notifications.success("A new Mortality Assessment Condition was created");
};

export const editMortalityAssessmentCondition = (id, newValues) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/mortality_assessment_condition/${id}/`;
  await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
};

export const deleteMortalityAssessmentCondition = (id, assessmentId) => async (dispatch, getState) => {
  await advisorhq.delete(`/api/mortality_assessment_condition/${id}/`);
  // re-fetch
  const response = await advisorhq.get("/api/mortality_assessment/" + assessmentId + "/");
  dispatch({ type: types.FETCH_MORTALITY_ASSESSMENT, payload: response.data });
};

export const generateCustomCurve = (id, data) => async (dispatch, getState) => {
  notifications.success("Generating Custom Curve...");
  const endpoint = `/api/mortality_assessment/${id}/generate_curve/`;
  const response = await advisorhq.post(endpoint, data);
  notifications.clearAll();
  notifications.success("Custom Curve generated");
  return response.data;
};
