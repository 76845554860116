export const compareIgnoreCase = (a, b) => {
  let r1 = a;
  if (typeof r1 === "string") {
    r1 = r1?.toLowerCase();
  }
  let r2 = b;
  if (typeof r2 === "string") {
    r2 = r2?.toLowerCase();
  }
  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
};

export const inlineEditSelectCellStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    minHeight: "36px",
    boxShadow: state.isFocused ? null : null,
    width: "200px",
  }),

  menu: (provided, state) => ({
    ...provided,
    width: "240px",
  }),
};

export const generateChoicesMapFromChoices = choices => {
  let CHOICES_MAP = {};
  for (const p of choices) {
    CHOICES_MAP[p.value] = p.label;
  }
  return CHOICES_MAP;
};

export const generateInitialValueFromValueAndChoicesMap = (value, CHOICES_MAP) => {
  let initialValue = {};
  if (value) {
    initialValue = {
      value: value,
      label: CHOICES_MAP[value],
    };
  }
  return initialValue;
};

export const generatePercentageCellChoices = () => {
  const choices = [];
  for (let i = 0; i < 101; i++) {
    choices.push({
      value: i,
      label: `${Number(i).toFixed(3)}%`,
    });
  }
  return choices;
};
