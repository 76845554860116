import { makeChoices } from "../../utils";

const recissionPeriodOptions = [
  "3 Days From Payment Receipt",
  "3 Days From Duly Executed Contracts",
  "15 Days From Payment Receipt",
  "15 Days from Duly Executed Contracts",
  "15 Days from Agreement Date",
  "30 Days From Payment Receipt",
  "30 Days from Duly Executed Contracts",
  "30 Days from Agreement Date",
  "60 Days From Payment Receipt",
  "60 Days from Duly Executed Contracts",
  "60 Days from Agreement Date",
  "Sooner of 30 Days from Duly Executed Contracts or 15 Days from Payment Receipt",
];

const providerLicensedOptions = ["Yes", "No", "Pending", "Preparing", "Not Regulated"];

const escrowFundingDateOptions = ["Escrow Funding Must Occur Prior to Change Form Delivery to Funder"];

const escrowReleaseDateOptions = [
  "Within 2 Business Days of Written Change Confirmation",
  "Within 3 Business Days of Written Change Confirmation",
  "Upon Escrow Agent Receipt of Written Confirmation",
];

const outOfStateFormsOptions = [
  "Seller & Insured - Can Sign In State",
  "Seller & Insured - Can Not Sign In State",
  "Only Insured - Can Sign In State",
];

const carrierVocProcessingRequirementOptions = ["30 Days from Receipt", "15 Days from Receipt", "No Requirement"];

const carrierChangeFormProcessingRequirementOptions = [
  "30 Days from Receipt",
  "15 Days from Receipt",
  "Shall Not Unreasonably Delay",
  "No Requirement",
];

export const providerCompensationChoices = [
  { value: "Yes", label: "Yes" },
  { value: "Not Required", label: "Not Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerCompensationChoices = [
  { value: "Not Required", label: "Not Required" },
  { value: "Yes - By Broker To Seller & Provider", label: "Yes - By Broker to Seller & Provider" },
  { value: "Yes - By Broker to Seller", label: "Yes - By Broker to Seller" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerBidHistoryChoices = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerPlanOfOperationChoices = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerLifeLicensedChoices = makeChoices([
  "Within 30 days of Contracts being Issued, must register as a Settlement Broker",
  "Within 10 days of Contracts being Issued, must register as a Settlement Broker",
  "Prior to Contracts being Issued, must file Settlement Declaration or Registration",
  "Prior to Contracts being Issued must have Settlement Broker's License",
  "Prior to Contracts being Issued, must have Life Producer License",
]);

export const brokerNotLifeLicensedChoices = makeChoices([
  "Within 30 days of Contracts being Issued, must register as a Settlement Broker",
  "Within 10 days of Contracts being Issued, must register as a Settlement Broker",
  "Prior to Contracts being Issued, must file Settlement Declaration or Registration",
  "Prior to Contracts being Issued must have Settlement Broker's License",
  "Prior to Contracts being Issued, must have Life Producer License",
]);

export const brokerBondingEOChoices = [
  { value: "Surety Bond Required", label: "Surety Bond Required" },
  { value: "E&O Required", label: "E&O Required" },
  { value: "Surety Bond or E&O Required", label: "Surety Bond or E&O Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const policyIssueDateOptions = [
  "None",
  "24+ Months",
  "24+ Months & 60+ Months For Term Conversions",
  "60+ Months",
  "TBD",
];

export const paymentAdvanceChoices = [
  { value: "Yes - With Fee", label: "Yes - With Fee" },
  { value: "Yes - No Fee", label: "Yes - No Fee" },
  { value: "Filing Rejected", label: "Filing Rejected" },
  { value: "Not Filed", label: "Not Filed" },
  { value: "Pending", label: "Pending" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const minimumPricingChoices = [
  { value: "Yes - CSV & ADB Amounts", label: "Yes - CSV & ADB Amounts" },
  { value: "Yes - See Pricing Guideline", label: "Yes - See Pricing Guideline" },
  { value: "No", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const leDeterminationChoices = [
  { value: "Licensed LE Providers Only", label: "Licensed LE Providers Only" },
  { value: "No Requirement", label: "No Requirement" },
  { value: "Based on Relevant Data", label: "Based on Relevant Data" },
  { value: "TBD", label: "TBD" },
  { value: "NA", label: "N/A" },
];

export const YesNoNaTbdChoices = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const requireNoticeCarrierPreContractChoices = [
  { value: "Yes - For Policies Issued 24 Months or Less", label: "Yes - For Policies Issued 24 Months or Less" },
  { value: "Yes - For Policies Issued 60 Months or Less", label: "Yes - For Policies Issued 60 Months or Less" },
  { value: "No", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const requireNoticeCarrierChoices = [
  { value: "Yes - 20 Days from Seller Signed Contract", label: "Yes - 20 Days from Seller Signed Contract" },
  { value: "Yes - 20 Days from Seller Signed All Docs", label: "Yes - 20 Days from Seller Signed All Docs" },
  { value: "Yes - 3 Days from Receipt of Required Items", label: "Yes - 3 Days from Receipt of Required Items" },
  { value: "Yes - 10 Days from Duly Executed Contract", label: "Yes - 10 Days from Duly Executed Contract" },
  { value: "Yes - For Contestable Policies Only", label: "Yes - For Contestable Policies Only" },
  { value: "Not Required", label: "Not Required" },
];

export const requireNoticeInsuredChoices = [
  { value: "Yes - At Time of Seller Payment", label: "Yes - At Time of Seller Payment" },
  { value: "Yes - 20 Days from Change Completion", label: "Yes - 20 Days from Change Completion" },
  { value: "Not Required", label: "Not Required" },
];

export const letterOfCompetencyChoices = [
  { value: "Yes - Required by Law", label: "Yes - Required by Law" },
  { value: "No - Not Required by Law", label: "No - Not Required by Law" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const disclosureToFunder = [
  { value: "Yes - With Contract", label: "Yes - With Contract" },
  { value: "Yes - Before Contract", label: "Yes - Before Contract" },
  { value: "Not Required", label: "Not Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const providerSellerDisclosure = [
  { value: "Yes - With Contract", label: "Yes - With Contract" },
  { value: "Yes - Before Contract", label: "Yes - Before Contract" },
  { value: "Not Required", label: "Not Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerCeChoices = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
  { value: "CONTINGENT REQUIREMENT", label: "Contingent Requirement" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const providerDocumentTypeChoices = [
  { value: 10, label: "Beneficiary Closing Packet" },
  { value: 3, label: "Carrier Notice" },
  { value: 11, label: "Carrier Pre-Contract Notice" },
  { value: 14, label: "Consumer Brochure" },
  { value: 6, label: "Insured Closing Packet" },
  { value: 2, label: "Insured Notice" },
  { value: 15, label: "IRS-W9" },
  { value: 12, label: "LISA State Guidelines" },
  { value: 7, label: "Pricing Guidelines" },
  { value: 9, label: "Provider Anti-Fraud Checklist" },
  { value: 0, label: "Provider Anti-Fraud Plan" },
  { value: 13, label: "Provider Plan of Operation" },
  { value: 8, label: "Sample State Closing Packet" },
  { value: 5, label: "Seller Closing Packet" },
  { value: 4, label: "Seller Notice" },
  { value: 1, label: "Verification of Coverage" },
];

export const brokerDocumentTypeChoices = [
  { value: 0, label: "Broker Anti-Fraud Plan" },
  { value: 1, label: "Broker & Seller Contract" },
  { value: 2, label: "Broker Compensation Disclosure" },
  { value: 3, label: "Broker Transactional Summary" },
  { value: 4, label: "Broker HIPAA" },
  { value: 5, label: "Broker Policy Authorization" },
  { value: 6, label: "Broker Information Sheet" },
  { value: 7, label: "Broker Plan of Operation" },
];

export const documentStatusChoices = [
  { value: 0, label: "Draft" },
  { value: 1, label: "In Use" },
  { value: 2, label: "Retired" },
];

export const recissionPeriodChoices = makeChoices(recissionPeriodOptions);
export const policyIssueDateChoices = makeChoices(policyIssueDateOptions);
export const providerLicensedChoices = makeChoices(providerLicensedOptions);
export const escrowFundingDateChoices = makeChoices(escrowFundingDateOptions);
export const escrowReleaseDateChoices = makeChoices(escrowReleaseDateOptions);
export const outOfStateFormsChoices = makeChoices(outOfStateFormsOptions);
export const carrierVocProcessingRequirementChoices = makeChoices(carrierVocProcessingRequirementOptions);
export const carrierChangeFormProcessingRequirementChoices = makeChoices(carrierChangeFormProcessingRequirementOptions);
