import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import MortalityAssessmentSummaryForm from "../MortalityAssessmentSummaryForm";
import { RecordsTable } from "components/table";
import FileInputsTable from "../FileInputsTable";
import SummaryForms from "./SummaryForms";
import HeightWeightTable from "./HeightWeightTable";
import { ButtonCreate } from "../../../../components/buttons";
import { createMortalityAssessmentSummary } from "../../../../actions";
import { activeMortalityAssessmentSelector } from "../../../../reducers";

const MortalityAssessmentSummary = ({ ...props }) => {
  let createSummaryActions = [
    <ButtonCreate onClick={() => props.createMortalityAssessmentSummary(props.mortalityAssessmentInfo.id)}>
      Create Medical Records Summary
    </ButtonCreate>,
  ];
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <MortalityAssessmentSummaryForm />
          <RecordsTable
            title="File Inputs"
            actionButtons={[createSummaryActions]}
            defaultSort={true}
            paginated={false}
            Table={FileInputsTable}
          />
          <SummaryForms />
          <RecordsTable
            title="Height & Weight"
            defaultSort={true}
            paginated={false}
            Table={HeightWeightTable}
            actionButtons={[]}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, { createMortalityAssessmentSummary })(MortalityAssessmentSummary);
