import Table, {
  choiceCell,
  datetimeCell,
  percentageCell,
  tooltipNotesCell,
  inlineEditSelectCell,
} from "components/table";
import React, { useState } from "react";
import { activeMortalityAssessmentSelector } from "reducers";
import { connect, useDispatch } from "react-redux";
import {
  PARAMETER_CATEGORY_CHOICES,
  PARAMETER_STATUS_CHOICES,
  PARAMETER_STATUS_CHOICES_MAP,
  PARAMETER_VERIFICATION_STATUS_CHOICES,
} from "../../../constants";
import IconLink from "components/IconLink";
import {
  createMortalityAssessmentCondition,
  editMortalityAssessmentCondition,
  selectMortalityAssessment,
  selectMortalityAssessmentCondition,
  showModal,
} from "actions";
import { FaCopy, FaEdit, FaTrash } from "react-icons/fa";
import ReactSelect from "react-select";
import { DELETE_CONDITION_LIST_MODAL, EDIT_CONDITION_LIST_MODAL } from "components/modals";
import * as notifications from "../../../../../notifications";
import { generatePercentageCellChoices } from "../../../../../components/table/utils";

const ConditionListTable = ({ mortalityAssessmentInfo, ...props }) => {
  const dispatch = useDispatch();
  if (!mortalityAssessmentInfo) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const id = row.original.id;
        const assessmentId = row.original.assessment_id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                props.selectMortalityAssessmentCondition(id);
                props.showModal(EDIT_CONDITION_LIST_MODAL, { id: id });
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete"
              onClick={() =>
                props.showModal(DELETE_CONDITION_LIST_MODAL, {
                  id: id,
                  assessmentId: assessmentId,
                })
              }
            />
            <IconLink
              Icon={FaCopy}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Copy"
              onClick={async () => {
                await props.createMortalityAssessmentCondition(row.original);
                props.selectMortalityAssessment(assessmentId);
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "parameter_status",
      Cell: inlineEditSelectCell(
        "parameter_status",
        editMortalityAssessmentCondition,
        PARAMETER_STATUS_CHOICES,
        dispatch,
      ),
    },
    {
      Header: "Verification Status",
      accessor: "validation_status",
      Cell: inlineEditSelectCell(
        "validation_status",
        editMortalityAssessmentCondition,
        PARAMETER_VERIFICATION_STATUS_CHOICES,
        dispatch,
      ),
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: choiceCell(PARAMETER_CATEGORY_CHOICES),
    },
    {
      Header: "Sub-Category",
      accessor: "sub_category",
    },
    {
      Header: "Type",
      accessor: "name",
    },
    {
      Header: "Summary",
      accessor: "summary",
      Cell: tooltipNotesCell,
    },
    {
      Header: "ICD Code(s)",
      accessor: "icd_codes",
    },
    {
      Header: "Extracted Keyword",
      accessor: "extracted_keyword",
    },
    {
      Header: "Extracted ICD Code",
      accessor: "extracted_icd_code",
    },
    {
      Header: "Debits & Credits",
      accessor: "debits_and_credits",
      Cell: inlineEditSelectCell(
        "debits_and_credits",
        editMortalityAssessmentCondition,
        generatePercentageCellChoices(),
        dispatch,
      ),
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
    },
    {
      Header: "Date Last Modified",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessmentInfo.condition_list || []}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Conditions found"
    />
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  createMortalityAssessmentCondition,
  editMortalityAssessmentCondition,
  selectMortalityAssessment,
  selectMortalityAssessmentCondition,
})(ConditionListTable);
