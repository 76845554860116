import { dateDisplay, datetimeDisplay, getLabel, currencyCellMoneyDisplay, timeDisplay } from "utils.js";
import React, { useState } from "react";
import ReactSelect from "react-select";
import * as moment from "moment";
import { isEmpty } from "lodash";
import { STATUS_CASE_TABLE_NOT_REQUIRED } from "../../constants";
import IconLink from "../IconLink";
import { FaQuestionCircle } from "react-icons/all";
import * as notifications from "../../notifications";
import {
  inlineEditSelectCellStyles,
  generateChoicesMapFromChoices,
  generateInitialValueFromValueAndChoicesMap,
} from "./utils";

export const dateCell = ({ value }) => {
  return dateDisplay(value) || "";
};

export const dateCellorPresent = ({ value }) => {
  return dateDisplay(value) || "Present";
};

export const dateOverdueCell = ({ value, row }) => {
  if (isEmpty(value)) {
    return "";
  }
  let now = moment();
  let date_formatted = moment(value);
  const status = row.original?.status || row.original?.task_obj?.status;
  const areSameDate = now.startOf("day").isSame(date_formatted.startOf("day"));

  // Status Completed is 2 for all tasks except Case Requirements, where we use 30, 31 and 32
  let isCompleted = status === 2;
  const isCaseRequirement = row.original.type === 1;
  if (isCaseRequirement) {
    isCompleted = status >= STATUS_CASE_TABLE_NOT_REQUIRED;
  }

  let className;
  if (isCompleted) {
    className = "completed";
  } else {
    if (areSameDate) {
      className = "dueToday";
    } else if (now > date_formatted) {
      className = "overdue";
    } else {
      className = "dueFuture";
    }
  }
  return <b className={"dueDateCell " + className}>{dateDisplay(value)}</b>;
};

export const datetimeCell = ({ value }) => {
  return datetimeDisplay(value) || "";
};

export const sortDateCell = (a, b) => {
  return new Date(a.values.date) - new Date(b.values.date);
};

export const sortNumberCell = (a, b) => {
  return a - b;
};

export const choiceCell =
  choices =>
  ({ value }) => {
    if (choices.getLabel) {
      return choices.getLabel(value) || "";
    } else {
      return getLabel(choices, value) || "";
    }
  };

export const percentageCell = ({ value }) => {
  if (value) {
    return `${Number(value).toFixed(3)}%`;
  }
  return "";
};

export const currencyCell = ({ value }) => {
  if (value) {
    const output = currencyCellMoneyDisplay(value);
    return <p style={{ textAlign: "center", margin: 0 }}>{output}</p>;
  }
  return "";
};

export const timeCell = ({ value }) => {
  if (value) {
    const output = timeDisplay(value);
    return <p style={{ textAlign: "center", margin: 0 }}>{output}</p>;
  }
  return "";
};

export const leftAlignedCurrencyCell = ({ value }) => {
  if (value) {
    const output = currencyCellMoneyDisplay(value);
    return <p style={{ textAlign: "left", marginBottom: 0 }}>{output}</p>;
  }
  return "";
};

export const idCell = ({ value }) => {
  if (value) {
    let prefix = "LR";
    let numeric_part = value % 1000;
    let alphabetic_natural_division_1 = Math.floor(value / 1000);
    let alphabetic_natural_division_2 = Math.floor(value / (1000 * 26));
    let first_letter = String.fromCharCode("A".charCodeAt(0) + (alphabetic_natural_division_1 % 26));
    let second_letter = String.fromCharCode("A".charCodeAt(0) + (alphabetic_natural_division_2 % 26));
    return `${prefix}-${second_letter}${first_letter}${numeric_part.toString().padStart(3, "0")}`;
  }
  return "";
};

export const notesCell = ({ value }) => {
  if (value) {
    return (
      <div className="text-wrap">
        {value
          .replaceAll(/(<([^>]+)>)/gi, " ")
          .replaceAll("&amp;nbsp;", " ")
          .replaceAll("&nbsp;", " ")}{" "}
      </div>
    );
  }
  return "";
};

export const shortNotesCell = ({ value }) => {
  if (value) {
    if (typeof value !== "string") return "";
    let notes = value
      .replaceAll(/(<([^>]+)>)/gi, " ")
      .replaceAll("&amp;nbsp;", " ")
      .replaceAll("&nbsp;", " ");
    if (notes.length > 250) {
      notes = notes.slice(0, 250) + "...";
    }
    return <div className="text-wrap">{notes} </div>;
  }
  return "";
};

export const tooltipNotesCell = ({ value }) => {
  if (value) {
    if (typeof value !== "string") return "";
    let replaced_value = value
      .replaceAll(/(<([^>]+)>)/gi, " ")
      .replaceAll("&amp;nbsp;", " ")
      .replaceAll("&nbsp;", " ");
    let notes = replaced_value;
    if (notes.length > 250) {
      notes = notes.slice(0, 250) + "...";
    }
    return (
      <div className="text-wrap">
        {notes}
        <IconLink
          Icon={FaQuestionCircle}
          iconConfig={{ className: "table--action-icon-tooltip" }}
          tooltip={replaced_value}
          tooltipPlacement={"bottom"}
        />
      </div>
    );
  }
  return "";
};

export const inlineEditSelectCell =
  (name, fn, choices, dispatch) =>
  ({ row, value }) => {
    const CHOICES_MAP = generateChoicesMapFromChoices(choices);
    let initialValue = generateInitialValueFromValueAndChoicesMap(value, CHOICES_MAP);
    let [valueState, setValueState] = useState(initialValue);

    const onChange = async e => {
      setValueState({ value: e.value, label: e.label });
      const obj = {};
      obj[name] = e.value;
      await dispatch(fn(row.original.id, obj));
      notifications.success("saved successfully");
    };

    return (
      <ReactSelect
        menuPortalTarget={document.body}
        maxMenuHeight={200}
        name={name}
        options={choices}
        onChange={onChange}
        value={valueState}
        className={`select`}
        menuPlacement="auto"
        styles={inlineEditSelectCellStyles}
        isDisabled={false}
      />
    );
  };
