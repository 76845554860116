import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { EditorField, FormCollapsible, Select, TextInput } from "components/forms";
import {
  createMortalityAssessmentCondition,
  editMortalityAssessmentCondition,
  fetchParameters,
  fetchPolicy,
  hideModal,
  selectMortalityAssessment,
} from "actions";
import { Form } from "react-bootstrap";
import { activeMortalityAssessmentConditionSelector, activeMortalityAssessmentSelector } from "reducers";
import WideSaveButton from "components/buttons/WideSaveButton";
import * as notifications from "notifications";
import { EDIT_CONDITION_LIST_MODAL } from "components/modals";
import { PARAMETER_STATUS_CHOICES, PARAMETER_VERIFICATION_STATUS_CHOICES } from "../../../constants";

const EditConditionListForm = ({ ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={props.mortalityAssessmentConditionInfo}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        await props.editMortalityAssessmentCondition(props.mortalityAssessmentConditionInfo.id, values);
        props.hideModal(EDIT_CONDITION_LIST_MODAL);
        notifications.success("Mortality Assessment Condition saved successfully");
        props.selectMortalityAssessment(props.mortalityAssessmentInfo.id);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={"Edit Mortality Assessment Condition"}>
            <Form.Row>
              <TextInput label="Category" name="category" disabled />
              <TextInput label="Sub-Category" name="sub_category" disabled />
              <TextInput label="Name" name="name" disabled />
              <TextInput label="ICD Code" name="icd_code" disabled />
            </Form.Row>
            <Form.Row>
              <TextInput label="Extracted Key Word" name="extracted_keyword" />
              <TextInput label="Extracted ICD Code" name="extracted_icd_code" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Status"
                name="parameter_status"
                placeholder="Select Status"
                options={PARAMETER_STATUS_CHOICES}
              />
              <Select
                label="Validation Status"
                name="validation_status"
                placeholder="Select Validation Status"
                options={PARAMETER_VERIFICATION_STATUS_CHOICES}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Debits & Credits +/-" name="debits_and_credits" placeholder="Enter %" append="%" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Condition Summary" name="summary" />
            </Form.Row>
            <WideSaveButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
    mortalityAssessmentConditionInfo: activeMortalityAssessmentConditionSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchPolicy,
  hideModal,
  fetchParameters,
  createMortalityAssessmentCondition,
  selectMortalityAssessment,
  editMortalityAssessmentCondition,
})(EditConditionListForm);
