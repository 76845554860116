import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { createMortalityAssessment, editInsuredInfo, selectLifeExpectancy, showModal } from "actions";
import { activeInsuredSelector, insuredSelector } from "reducers";
import * as notifications from "../../../notifications";
import {
  TextInput,
  GenderSelect,
  BooleanSelect,
  Select,
  SSNInput,
  DateInput,
  SubmitButton,
  PhoneInput,
  StateSelect,
} from "components/forms";
import Collapsible from "../../../components/Collapsible";
import { RecordsTable } from "../../../components/table";
import { LifeExpectancyTable } from "../lifeExpectancies";
import { isAdmin } from "permissions";
import {
  INSURED_STATUS_DECEASED as DECEASED,
  maritalStatusChoices,
  MARITAL_STATUS_MARRIED as MARRIED,
  MARITAL_STATUS_SEPARATED as SEPARATED,
  insuredStatusChoices,
} from "../../../constants";
import { ButtonCreate } from "../../../components/buttons";
import { sideButtonStyle } from "../../../components/utils";

const InsuredForm = ({ insuredInfo, hasAdminPermission, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  if (!insuredInfo) {
    return null;
  }
  const {
    id,
    case: case_id,
    first_name,
    middle_initial,
    last_name,
    suffix,
    gender,
    date_of_birth,
    social_security,
    insured_status,
    date_of_death,
    marital_status,
    spouse_name,
    active_POA,
    POA_name,
    bankruptcy,
    mobile_phone,
    home_phone,
    office_phone,
    state,
    city,
    street_address,
    zipcode,
    email,
    underwriting_date,
    primary_health_impairment,
    is_insured_chronically_ill,
    is_insured_terminally_ill,
  } = insuredInfo;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name,
          middle_initial,
          last_name,
          suffix,
          gender,
          date_of_birth,
          social_security,
          insured_status,
          date_of_death,
          marital_status,
          spouse_name,
          active_POA,
          POA_name,
          bankruptcy,
          mobile_phone,
          home_phone,
          office_phone,
          state,
          city,
          street_address,
          zipcode,
          email,
          underwriting_date,
          primary_health_impairment,
          is_insured_chronically_ill,
          is_insured_terminally_ill,
        }}
        validationSchema={Yup.object({
          date_of_death: Yup.date()
            .nullable()
            .when("insured_status", {
              is: `${DECEASED}`,
              then: schema => Yup.date().nullable(),
            }),
          spouse_name: Yup.string().when("marital_status", {
            is: marital_status => marital_status !== MARRIED && marital_status !== SEPARATED,
            then: schema => Yup.string().max(0, "Spouse name is only valid for Married and Separated Marital Status"),
          }),
          POA_name: Yup.string().when("active_POA", {
            is: "false",
            then: schema => Yup.string().max(0, "POA name is only valid when 'POA in Effect'"),
          }),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await props.editInsuredInfo(insuredInfo.id, values);
            notifications.success("Insured Information saved successfully");
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Could not save changes, please verify the form errors.");
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => {
          function renderCreateMortalityAssessment() {
            return (
              !props.readMode && (
                <ButtonCreate
                  onClick={async e => {
                    e.preventDefault();
                    setLoading(true);
                    await props.createMortalityAssessment({ case_id: case_id, insured_id: id });
                    setLoading(false);
                  }}
                  key="create"
                  className="btn--pricing-setup"
                  style={sideButtonStyle}
                  disabled={loading}
                >
                  Create Mortality Assessment
                </ButtonCreate>
              )
            );
          }
          return (
            <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
              <Collapsible title="Demographic Information">
                <Form.Row>
                  <TextInput label="First Name" name="first_name" md={4} isRequired disabled={!hasAdminPermission} />
                  <TextInput label="Middle Initial" name="middle_initial" md={2} disabled={!hasAdminPermission} />
                  <TextInput label="Last Name" name="last_name" md={4} isRequired disabled={!hasAdminPermission} />
                  <TextInput label="Suffix" name="suffix" md={2} disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <GenderSelect isRequired disabled={!hasAdminPermission} />
                  <DateInput label="Date of Birth" name="date_of_birth" isRequired disabled={!hasAdminPermission} />
                  <SSNInput isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Insured Status"
                    name="insured_status"
                    placeholder="Select Insured Status"
                    options={insuredStatusChoices}
                    md="4"
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                  <DateInput
                    label="Date of Death"
                    name="date_of_death"
                    md="4"
                    disabled={values.insured_status !== DECEASED || !hasAdminPermission}
                    isRequired={values.insured_status === DECEASED}
                  />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Marital Status"
                    name="marital_status"
                    placeholder="Select Marital Status"
                    options={maritalStatusChoices}
                    md="4"
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                  <TextInput
                    label="Spouse Name"
                    name="spouse_name"
                    isRequired={values.marital_status === MARRIED || values.marital_status === SEPARATED}
                    disabled={
                      (values.marital_status !== MARRIED && values.marital_status !== SEPARATED) || !hasAdminPermission
                    }
                  />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect
                    label="POA In Effect?"
                    name="active_POA"
                    placeholder="Select POA in effect"
                    md={4}
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                  <TextInput
                    label="POA Name"
                    name="POA_name"
                    isRequired={values.active_POA === true}
                    disabled={values.active_POA !== true || !hasAdminPermission}
                  />
                  <BooleanSelect
                    label="Bankruptcy?"
                    name="bankruptcy"
                    placeholder="Select choice"
                    md={4}
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
              </Collapsible>
              <Collapsible title="Contact Information">
                <Form.Row>
                  <PhoneInput label="Mobile Phone" name="mobile_phone" isRequired disabled={!hasAdminPermission} />
                  <PhoneInput label="Home Phone" name="home_phone" isRequired disabled={!hasAdminPermission} />
                  <PhoneInput label="Office Phone" name="office_phone" isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="City" name="city" isRequired disabled={!hasAdminPermission} />
                  <StateSelect isRequired name="state" disabled={!hasAdminPermission} />
                  <TextInput label="Zip Code" name="zipcode" isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <TextInput
                    label="Email Address"
                    lowercase={true}
                    name="email"
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
              </Collapsible>
              {hasAdminPermission && (
                <Collapsible title="Underwriting">
                  <section id="underwriting" className="scroll-spied">
                    <Form.Row>
                      {renderCreateMortalityAssessment()}
                      <DateInput label="Underwriting Date" name="underwriting_date" disabled={!hasAdminPermission} />
                      <TextInput
                        label="Primary Health Impairment"
                        name="primary_health_impairment"
                        disabled={!hasAdminPermission}
                      />
                    </Form.Row>
                    <Form.Row>
                      <BooleanSelect
                        label="Is Insured Chronically Ill"
                        name="is_insured_chronically_ill"
                        disabled={!hasAdminPermission}
                      />
                      <BooleanSelect
                        label="Is Insured Terminally Ill"
                        name="is_insured_terminally_ill"
                        disabled={!hasAdminPermission}
                      />
                    </Form.Row>
                    <RecordsTable title="Life Expectancies" actionButtons={[]} Table={LifeExpectancyTable} />
                  </section>
                </Collapsible>
              )}
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    insuredInfo: ownProps.insuredId ? insuredSelector(state, ownProps.insuredId) : activeInsuredSelector(state),
    activeInsured: activeInsuredSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  editInsuredInfo,
  createMortalityAssessment,
  selectLifeExpectancy,
  showModal,
})(InsuredForm);
